<template>
    <div>
        <b-card>
            <span class="uploadDoc" @click="$refs.inputFile.click()" :aria-disabled="isSpinner==true">
                <a href="#" :aria-disabled="isSpinner==true">Upload File</a>
                <b-img :src="require('@/assets/images/icons/onedrive.png')" class="upload_img_inner">
                </b-img>
            </span>
        </b-card>  
        <b-card>
            <b-row style="margin-bottom: 1.5rem;">     
                <!-- SEARCH INPUT -->
                <b-col md="2" sm="4">
                  <b-form-group    
                      class="mb-0"
                  >     
                      <b-form-input
                          id="filterInput"
                          v-model="filter"
                          type="search"
                          placeholder="Type to Search"
                      />
                  </b-form-group>
                </b-col>
                <!-- PAGINATION DROPDOWN -->
                <b-col md="10" style="display: flex;justify-content: end;">
                  <v-select
                    v-model="perPage"
                    style="width: 90px"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="pageOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block mx-50"
                  />
                </b-col>
            </b-row>
            <b-row>
                <!-- TABLE -->
                <b-col cols="12">
                <b-table
                    striped
                    hover
                    responsive
                    show-empty
                    empty-text="No matching records found"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :items="getFilteredData"
                    v-model="companyDocTable"
                    :fields="fields"
                    class="company_document_table position-relative padding-custom"
                >
                    <template #cell(id)="data">
                        {{((currentPage-1) * perPage + data.index + 1)>9?'':0}}{{(currentPage-1) * perPage + data.index + 1}}
                    </template>
                    <template #cell(document_name)="data">
                      <validation-observer :ref="'documentNameRef'+data.item.id">
                        <div>
                          <span v-if="data.item.document_name && !data.item.edit">
                            {{data.item.document_name}}
                            <img src='@/assets/images/erflog/Edit.png' @click.prevent="data.item.edit=true"/>
                          </span>
                          <validation-provider
                            v-else
                            #default="{ errors }"
                            :vid="'document name'+data.item.id"
                            name="document Name"
                            rules="required"
                          >
                            <b-form-input
                              v-model="data.item.document_name"
                              placeholder="Enter Text"
                              @blur="data.item.document_name=data.item.document_name.trim()"
                            />
                            <span v-if="data.item.document_name=='' || data.item.edit">
                              <img src='@/assets/images/erflog/UploadDoc.png' class="action_icon" @click.prevent="saveNewDocumentName(data)"/>
                              <img src='@/assets/images/erflog/Rejected.png' class="action_icon" @click.prevent="resetField(data)"/>
                            </span>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </div>
                      </validation-observer>
                    </template>
                    
                    <template #cell(action)="data">
                        <!-- {{data}} -->
                        <div v-if="$route.meta.action=='write' || $route.meta.action==undefined">
                          <img src='@/assets/images/erflog/Download.png' :aria-disabled="isSpinner==true" @click="downloadAttachment(data)" class="action_icon"/>
                          <img src='@/assets/images/erflog/Delete.png' :disabled='isSpinner==true' @click.prevent="selectedRemoveData=data" v-b-modal.delete-field class="action_icon"/>
                        </div>
                        <div v-else>
                          --
                        </div>
                    </template>
                </b-table>
                <!-- <p v-show="allData.length == 0" style="color: red;font-size: small;font-style: italic;text-align: center;">No Data Found</p> -->
                </b-col>              
            </b-row>
              <div class="mx-2 mb-2">
                <b-row>
                  <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-start"
                  >
                    <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
                  </b-col>
                  <!-- PAGINATION FOOTER -->
                  <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="this.getFilteredData.length"
                      :per-page="perPage"
                      align="right"
                      first-number
                      last-number
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0 custom_pagination"
                  >
                  <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
                </b-pagination>
                  </b-col>
                </b-row>
                </div>
        </b-card>
        <div v-if="isSpinner" class="loading loading-custom">         
        <div class="effect-1 effects"></div>
        <div class="effect-2 effects"></div>
        <div class="effect-3 effects"></div>     
       </div>
        <input type="file" ref="inputFile" id="fileOutput" style="display: none" :disabled='isSpinner==true' @change="changeFile" multiple="multiple">
        <!-- DELETE MODEL -->
        <b-modal
        id="delete-field"
        cancel-variant="outline-secondary"
        ok-title="Yes"
        cancel-title="No"
        centered
        title=""
        :no-close-on-backdrop="true"
        @hidden="selectedRemoveData=''"
        v-if="selectedRemoveData!=''"
        @cancel='isSpiner=false'
        @ok="deleteField(selectedRemoveData)"
      >
       <div class="create_account_img">
      <img src="@/assets/images/erflog/DeletePopup.png" alt="">
    </div>
      <div style="text-align: center;" v-if="selectedRemoveData!=''">
        <label class="s_modal_title">Delete Field</label>
        <p>Are you sure you want to delete these document category?</p>
      </div>
       </b-modal>
    </div>
</template>

<script>
import {
  BTable, BAvatar, BBadge, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton,BCard, BImg
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver,extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules';
extend('required',required)

import vSelect from 'vue-select'
import * as uploadFile from '@/utils/FirebaseQueries/storageQuery/storageQueries.js'
import * as inserQuery from '@/utils/FirebaseQueries/insertQueries/insertQueries.js'
import firebase from "firebase"
import fb from "@/utils/firebaseInit.js"
const db = fb.firestore()
import { dbCollections } from '@/utils/firebaseCollection'
import * as updateQuery from '@/utils/FirebaseQueries/updateQueries/updateQueries.js'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import * as getQuery from '@/utils/FirebaseQueries/getQueries/getQueries.js'
import Ripple from 'vue-ripple-directive'
// import axios from 'axios'
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    vSelect,
    BImg
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      //FIREBASE
      storageFolder:'companyDocuments', 
      isSpinner:false, 
      perPage: 10,
      pageOptions: [10, 25, 50],
      totalRows: 1,
      currentPage: 1,
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        // {
        //   key: 'id', label: 'No.',
        // },
        // {
        //   key: 'docExtension', label: 'Icon',
        // },
        {
          key: 'document_name', label: 'Document Name',sortable: true
        },
        {
          key: 'companyDoc.imageName', label: 'File Name',sortable: true
        }, 
        {
          key: 'updated_date', label: 'Date Added',sortable: true
        }, 
        {
          key: 'action', label: 'Action',
        },      
      ],
      months:["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"],
      documentName:'',
      allData:[],
      selectedRemoveData:{},
      companyDocTable:[],
      tempAllData:[],
      totalUsers:0 
    }
  },
  computed: {
    getFilteredData()
    {
      var self = this

      var tmp = self.allData
      var dispArr = []

      if(self.filter!='' && self.filter!=null)
      {
        tmp.forEach(data=>{
          if(
            data.document_name.toLowerCase().includes(self.filter.toLowerCase())
            || data.companyDoc.imageName.toLowerCase().includes(self.filter.toLowerCase())
            || data.updated_date.toLowerCase().includes(self.filter.toLowerCase())
          )
          {
            dispArr.push(data)
          }
        })
      }
      else
      {
        dispArr = tmp
      }

      console.log("Total")
      return dispArr;
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    dataMeta(){
    const localItemsCount = this.companyDocTable ? this.companyDocTable.length : 0   
    if(this.filter !== "" && this.filter !== null){
      // console.log("search",this.totalRows)
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.getFilteredData.length,
      }
    }else{
      // console.log("not search")
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.allData.length,
      }
    }
  }
  },
  watch: {
    $route: function () {        
        this.getData();          
    }
  },  
  created(){
      this.getData();
  },
  methods: {
    resetField(data)
    {
      var self = this
      var refr = 'documentNameRef'+data.item.id
      console.log("Condition",data.item.document_name!='' , data.item.document_name!=data.item.prev_name)
      if(data.item.document_name!='')
      {
        console.log("Reset")
        data.item.edit=false;
        data.item.document_name=data.item.prev_name;
      }

      self.$refs[refr].reset()

    },
    saveNewDocumentName(data)
    {
      var self = this
      // console.log("Doc Name",self.documentName,data.item)
      var refr = 'documentNameRef'+data.item.id

      self.$refs[refr].validate().then(success => {
        if(success)
        {
          db
            .collection(dbCollections.COMPANYDOCUMENTS)
            .doc(data.item.id)
            .update({
              updatedAt : firebase.firestore.FieldValue.serverTimestamp(),
              documentName:data.item.document_name
            })
            .then(()=>{
              self.$root.$emit('showToastMessage','Document name updated successfully.','success')
              self.documentName=''
              data.item.edit = false
            })
            .catch(error=>{
              console.log(error)
              self.$root.$emit('showToastMessage',error.message,'danger')
            })
        }
        else
        {
          console.log("Success",success)
        }
      })
    },
    getData(){
        var self = this;
        getQuery.getDataWithSpecificWhereCondition(dbCollections.COMPANYDOCUMENTS,'isDeleted','==',false,response=>{
            self.allData = [];
            if(response.length > 0){
               response.forEach(element => {
                   if(element){
                       element.ext = element.companyDoc.imageName.split('.').pop();
                       self.allData.push({
                           'id':element.id,
                           'document_name':element.documentName||'',
                           'prev_name':element.documentName||'',
                           'companyDoc':element.companyDoc,
                           'docExtension':element.ext,
                           'edit':element.documentName || element.documentName?false:true,
                           'updated_date':element.updatedAt?`${new Date(element.updatedAt.seconds * 1000).getDate()} ${self.months[new Date(element.updatedAt.seconds * 1000).getMonth()]}, ${new Date(element.updatedAt.seconds * 1000).getFullYear()}`:'',
                           'action':''
                       })
                       self.tempAllData= self.allData; 
                       self.totalUsers = self.allData.length
                   }
               });
            }
        }) 
    },  
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      if(this.filter == ""){
      this.totalUsers = this.allData.length;
      }else{      
      this.totalUsers = this.companyDocTable.length
      }
      this.currentPage = 1
    },
    changeFile(){
      var self = this; 
      let fileArry = self.$refs.inputFile.files; 
        if(fileArry.length > 0){
            let count = 0;
            const uploadFileFunction = (row) => {
               if(count >= fileArry.length){   
                    self.isSpinner=false;
                    self.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: 'Document Added Successfully',
                                icon: 'CoffeeIcon',
                                variant: 'success',                               
                            },
                    })
                    return;       
               }
                self.uploadCompanyFile(row,(saveRes)=>{                  
                     let idObj = {
                    'id':saveRes.id
                     }                
                    updateQuery.updateRootCollectionDataByDocId(dbCollections.COMPANYDOCUMENTS,saveRes.id,idObj,updateRes=>{
                        console.log(updateRes);                  
                    })
                    setTimeout(()=>{
                        count += 1;
                        uploadFileFunction(fileArry[count]);
                    },1000)                   
                });              
            }
            self.isSpinner=true;
            uploadFileFunction(fileArry[count]);
        }
    },
    uploadCompanyFile(row,cb){
          var self = this;
          let fileObject = row
          const filename = fileObject.name;
          const ext = filename.split('.').pop();
          console.log(fileObject,filename,ext)         
          if(fileObject !== undefined){
            uploadFile.uploadImageFileWithoutUniqueString(self.storageFolder,filename,fileObject,resp=>{
            let obj = {
              companyDoc:resp,
              createdAt : firebase.firestore.FieldValue.serverTimestamp(),
              updatedAt : firebase.firestore.FieldValue.serverTimestamp(),
              isDeleted: false,
            }
            inserQuery.addDataToRootCollection(dbCollections.COMPANYDOCUMENTS,obj,res=>{
                console.log(res,"?")
                cb(res)               
            })
        })      
          }
    },
    downloadAttachment(data){
        console.log(data,"JYOTIIIIII")  
        this.downloadDocument(data.item.companyDoc.imageUrl,data.item.companyDoc.imageName)       
    },

    deleteCompanyDoc(data){
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText:'No',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.isSpinner = true;  
          let obj = {
                'isDeleted':true
          }
          updateQuery.updateRootCollectionDataByDocId(dbCollections.COMPANYDOCUMENTS,data.item.id,obj,res=>{
                console.log(res,"?") 
                this.isSpinner = false;  
                this.$swal({
                    icon: 'success',
                    title: 'Deleted!',
                    text: 'Your file has been deleted.',
                    customClass: {
                        confirmButton: 'btn btn-success',
                    },
                })  
          })         
        }
      })
    },
    deleteField(data){
       this.isSpinner = true;  
          let obj = {
                'isDeleted':true
          }
          updateQuery.updateRootCollectionDataByDocId(dbCollections.COMPANYDOCUMENTS,data.item.id,obj,res=>{
                console.log(res,"?") 
                this.isSpinner = false;  
                 this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Document Deleted Successfully',
                            icon: 'CoffeeIcon',
                            variant: 'success',                               
                        },
                }) 
          })
    },   
    downloadDocument(url,name){
      console.log("url,,,,,,,,,",url);
      this.isSpinner = true;
      var naming = name.split('.');      
      this.$axios({
            url: url,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download',name);
            document.body.appendChild(fileLink);
            fileLink.click(); 
            this.isSpinner = false;
        })
        .catch(error=>{
             this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: error,
                        icon: 'CoffeeIcon',
                        variant: 'danger',                               
                    },
            }) 
            this.isSpinner = false;
        })
    }, 
  },
}
</script>
<style scoped>
.uploadDoc{
    text-align: center;
    padding: 0;
    border: 1px #c1c1c1 dashed;
    background-color: #ededed;
    width: 100%;
    margin: 0 auto;
    padding: 9px;
    height: 145px;
    border-radius: 5px;
    display: block;
    line-height: 130px;
}
span.uploadDoc img {
    /* width: 22px; */
    position: absolute;
    top: 67%;
    left: 50%;
    transform: translateX(-50%);
}
span.uploadDoc a {
    color: #000;
    font-size: 14px;
    font-weight: 500;
}

@-webkit-keyframes rotate {
  /* 100% keyframe for  clockwise. 
     use 0% instead for anticlockwise */
  100% {
    -webkit-transform: rotate(360deg);
  }
}
</style>
 